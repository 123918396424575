.App {
  text-align: center;
}

.bg {
    background: url('images/279108050_10159156467544608_8106435010553167224_n.jpeg') no-repeat center fixed;     
    background-size: cover;
    width: 100vw;
    height: 100vh;
    box-shadow: 0 0 5px 2px #282a2d;
    display: flex;
    align-items: center;
    justify-content: center;
}

.appbar {
  background: transparent!important;
}

.random-button {
  background-color: #04AA6D; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.bg-tint {
  background-color: rgba(0, 0, 0, 0.75);
  background-blend-mode: multiply;
}


.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
